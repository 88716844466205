import React, { ReactNode, useState } from 'react';
import { ArrowUpRight, Menu, X, TrendingUp, Battery, Zap, DollarSign, LucideIcon } from 'lucide-react';

interface HeaderProps {
  toggleMenu: () => void;
}

interface MobileMenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
}

interface CardProps {
  title: string;
  children: ReactNode;
  icon?: LucideIcon;
}

interface QuoteProps {
  text: string;
  author: string;
}

interface SectionProps {
  id: string;
  title: string;
  children: ReactNode;
}


const Header: React.FC<HeaderProps> = ({ toggleMenu }) => (
  <header className="bg-white py-4 px-6 flex justify-between items-center fixed w-full z-10 shadow-md">
    <h1 className="text-2xl font-bold text-blue-900">Blastoise Capital</h1>
    <button onClick={toggleMenu} className="md:hidden">
      <Menu size={24} />
    </button>
    <nav className="hidden md:flex space-x-6">
      <NavLinks />
    </nav>
  </header>
);

const NavLinks = () => (
  <>
    <a href="#about" className="text-gray-600 hover:text-blue-900">About</a>
    <a href="#philosophy" className="text-gray-600 hover:text-blue-900">Philosophy</a>
    <a href="#why-us" className="text-gray-600 hover:text-blue-900">Why Us</a>
    <a href="#testimonials" className="text-gray-600 hover:text-blue-900">Testimonials</a>
    <a href="#contact" className="text-gray-600 hover:text-blue-900">Contact</a>
  </>
);

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, toggleMenu }) => (
    <div className={`fixed inset-0 bg-white z-20 transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out md:hidden`}>
    <div className="flex justify-end p-4">
      <button onClick={toggleMenu}>
        <X size={24} />
      </button>
    </div>
    <nav className="flex flex-col items-center space-y-6 mt-20">
      <NavLinks />
    </nav>
  </div>
);

const Hero = () => (
  <section className="bg-gradient-to-r from-blue-900 to-blue-700 text-white py-32 px-6">
    <div className="max-w-4xl mx-auto text-center">
      <h2 className="text-4xl md:text-6xl font-bold mb-6">Investing In Friendship</h2>
      <p className="text-xl mb-8">And 5G from Space.</p>
      <a href="#contact" className="bg-white text-blue-900 py-3 px-8 rounded-full font-bold hover:bg-blue-100 transition duration-300">TOAST TO INDUSTRY</a>
    </div>
  </section>
);

const Card: React.FC<CardProps> = ({ title, children, icon: Icon }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    {Icon && <Icon size={40} className="text-blue-900 mb-4" />}
    <h3 className="text-xl font-bold mb-4 text-blue-900">{title}</h3>
    {children}
  </div>
);

const Quote: React.FC<QuoteProps> = ({ text, author }) => (
  <blockquote className="border-l-4 border-blue-500 pl-4 my-4 italic">
    <p className="text-lg text-gray-700">{text}</p>
    <footer className="text-gray-600 mt-2">- {author}</footer>
  </blockquote>
);

const Section: React.FC<SectionProps> = ({ id, title, children }) => (
  <section id={id} className="py-16 px-6">
    <div className="max-w-6xl mx-auto">
      <h2 className="text-3xl font-bold mb-10 text-blue-900 text-center">{title}</h2>
      {children}
    </div>
  </section>
);

const App: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className="font-sans bg-gray-100">
      <Header toggleMenu={toggleMenu} />
      <MobileMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <main className="pt-16">
        <Hero />
        <Section id="about" title="About Us">
          <div className="grid md:grid-cols-2 gap-8">
            <Card title="Our Vision" icon={TrendingUp}>
              <p className="text-gray-700 mb-4">At Blastoise Capital, we're not just investors - we're visionaries. Founded by a group of friends with an unwavering belief in the power of space-based telecommunications, we've made it our mission to ride the ASTS rocket to the moon (or at least to Ibiza).</p>
            </Card>
            <Card title="Our Motto" icon={Zap}>
              <Quote text="Buy high, sell low baby" author="Joe" />
            </Card>
          </div>
        </Section>
        <Section id="philosophy" title="Our Investment Philosophy">
          <Card title="Strategic Approach" icon={Battery}>
          <Quote text="We at Blastoise capital don’t follow the rules: the rules follow us. So whatever you choose to do is correct." author="Joe" />
          <Quote text="I’m irresponsible." author="Chris" />
          </Card>
        </Section>
        <Section id="why-us" title="Why Choose Us">
          <div className="grid md:grid-cols-3 gap-8">
            <Card title="Unmatched Enthusiasm">
              <Quote text="We're all geniuses and ASTS is gonna moon" author="Avani" />
            </Card>
            <Card title="High-Risk, High-Reward">
              <Quote text="I'm not here to make money. I'm here to make dreams come true." author="Joe" />
            </Card>
            <Card title="Cutting-Edge Analysis">
              <Quote text="Monzo do loans." author="Chris" />
              <Quote text="I told my parents about asts and they’re not convinced." author="Joe" />
            </Card>
          </div>
          <div className="mt-8">
            <Card title="Our Promise" icon={DollarSign}>
              <Quote text="At $30 we're going to Ibiza" author="The Blastoise Capital Team" />
            </Card>
          </div>
        </Section>
        <Section id="testimonials" title="Investor Testimonials">
          <div className="grid md:grid-cols-2 gap-8">
            <Card title="Micro Gains">
              <Quote text="I've made £4 today. This is the kinda win I was after" author="Joe" />
            </Card>
            <Card title="Emotional Rollercoaster">
              <Quote text="I'm creaming" author="Avani, on a good trading day" />
              <Quote text="We lost some brave pounds that day. BRAVE POUNDS." author="Sam, on a bad trading day" />
            </Card>
          </div>
        </Section>
        <Section id="vision" title="Our Vision">
          <Card title="Future Plans">
            <Quote text="When ASTS hits $500, we're buying an underwater mansion" author="Ki Fieri" />
            <Quote text="I’ll buy Henry’s yacht." author="Chris" />
          </Card>
        </Section>
        <Section id="disclaimer" title="Disclaimer">
          <Card title="Important Notice">
            <p className="text-gray-700">Past performance does not guarantee future results. Invest at your own risk. Remember, as we always say, "We are all geniuses" - until we're not.</p>
            <p className="text-gray-700 mt-4">Note: We are not affiliated with The Pokémon Company or AST SpaceMobile. We just think Blastoise and 5G from space are cool.</p>

          </Card>
        </Section>
        <Section id="contact" title="Join the Revolution">
          <Card title="Get in Touch">
            <p className="text-gray-700 mb-4">For more information on how to join the Blastoise Capital journey to financial glory (or glorious financial ruin), reach out to us via a 5G connection from space.</p>
            <a href="mailto:ollie@blastoise.capital" className="inline-flex items-center bg-blue-900 text-white py-3 px-8 rounded-full font-bold hover:bg-blue-800 transition duration-300">
              Contact Us
              <ArrowUpRight className="ml-2" size={20} />
            </a>
          </Card>
        </Section>
      </main>
      <footer className="bg-blue-900 text-white py-8 px-6 text-center">
  <blockquote className="border-l-4 border-blue-500 pl-4 my-4 italic">
    <p className="text-lg text-white">Remember: It's ASTS or nothing really</p>
    <footer className="text-white mt-2">- Joe</footer>
  </blockquote>
</footer>
    </div>
  );
};

export default App;
